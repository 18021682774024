import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'

import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import * as Utils from '@/util/utils'
import * as DB from '@/util/db'
import ApiRequest from "@/api/ApiRequest";


const app = createApp(App)
app.use(ElementPlus)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.config.globalProperties.$utils = Utils
app.config.globalProperties.$db = DB
app.config.globalProperties.$api = new ApiRequest()
app.mount('#app')