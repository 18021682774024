
// 时间字符串转时间对象
export function dateStrToDate(dateStr) {
    let date = dateStr.substring(0, 19);
    date = date.replace(/-/g, '/');
    return new Date(date);
}

// 时间字符串转时间戳
export function dateStrToTime(dateStr) {
    return dateStrToDate(dateStr).getTime();
}

// 冒泡排序
export function bubbleSort(list, sortKey, sortDirection) {
    let len = list.length
    for (let i = 0; i < len - 1; i++) {
        let temp = null
        for (let j = 0; j < len - i - 1; j++) {
            if (list[j][sortKey] < list[j + 1][sortKey]) {
                temp = list[j]
                list[j] = list[j + 1]
                list[j + 1] = temp
            }
        }
    }
    if (sortDirection === 'asc') {
        list = list.reverse();
    }
    return list;
}

// 解析地址参数
export function parseUrlParams(url) {
    const query = url.search.substring(1);
    const pattern = /([^&=]+)=([^&]*)/g;
    const result = {};
    let match;
    while ((match = pattern.exec(query))) {
        const key = decodeURIComponent(match[1]);
        const value = decodeURIComponent(match[2]);
        if (result[key]) {
            if (Array.isArray(result[key])) {
                result[key].push(value);
            } else {
                result[key] = [result[key], value];
            }
        } else {
            result[key] = value;
        }
    }
    return result;
}
