<template>
	<div class="lxs-no-data">
		<div class="data-list-empty">
			<div class="img">
        <img :src="require('@/assets/common-nothing-1.png')" />
      </div>
			<div class="str">{{ content }}</div>
		</div>
	</div>
</template>

<script>
  export default {
    name: 'LxsNoData',
    props: {
			content: {
				type: String,
				default: '没有符合条件的数据'
			},
		},
		data() {
			return {
				
			}
		},
		methods: {
			
		}
	}
</script>

<style scoped>
.lxs-no-data {

}

.lxs-no-data .data-list-empty {
  width: 200px;
  height: 220px;
  margin: auto;
}

.lxs-no-data .data-list-empty .img {
  width: 180px;
  height: 180px;
  padding: 10px;
}

.lxs-no-data .data-list-empty img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.lxs-no-data .data-list-empty .str {
  text-align: center;
  font-size: 14px;
  color: #999;
}
</style>
