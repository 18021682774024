/**
 * WebSocket客户端
 */
export default class WebSocketClient {
    // 构造器
    constructor(url, httpPrefix, openCallback, closeCallback, messageCallback) {
        this.printLog('WebSocket请求地址' + url)
        this.websocket = null
        this.heartbeatInterval = null  // 心跳定时器
        this.promisePool = {}  // 线程池

        this.openCallback = openCallback  // 打开回调
        this.closeCallback = closeCallback  // 关闭回调
        this.messageCallback = messageCallback  // 消息回调
        this.url = url  // 地址
        this.httpPrefix = httpPrefix  // 接口前缀

        this.pingInterval = 5000  // ping间隔事件（毫秒）
        this.reconnectInterval = 3000  // 重连间隔时间（毫秒）
        this.reconnectCount = 0  // 重连次数
        this.reconnectMaxCount = 10  // 最大重连次数
    }

    // 打开连接
    open() {
        if (this.websocket === null) {
            this.websocket = new WebSocket(this.url)
        }
        this.websocket.onopen = () => {
            this.printLog('WebSocket已打开')
            this.reconnectCount = 0
            this.startHeartbeat();
            this.openCallback && this.openCallback()
        }
        this.websocket.onclose = () => {
            this.printLog('WebSocket已关闭')
            this.websocket = null
            this.reconnect()
            this.stopHeartbeat()
            this.closeCallback && this.closeCallback()
        }
        this.websocket.onmessage = (event) => {
            try {
                if (event.data) {
                    let message = JSON.parse(event.data)
                    let key = message.response_id
                    let promise = this.promisePool[key]
                    delete message.response_id

                    if (key && promise) {
                        if (promise.type.indexOf('ping') === -1) {
                            console.log(message)
                        }
                        if (promise.type + '_HttpSuccess' === message.type) {
                            if (message.data.code === 200) {
                                promise.resolve(message.data.data)
                            } else {
                                promise.reject(message.data.msg)
                            }
                        } else if (promise.type + '_HttpFail' === message.type)  {
                            promise.reject(message.msg)
                        }
                        delete this.promisePool[key]
                    } else {
                        this.printLog('接到服务器主动发送的消息,' + event.data)
                        this.messageCallback && this.messageCallback(message)
                    }
                }
            } catch (e) {
                this.printLog('处理消息异常')
                console.log(e)
            }
        }
    }

    // 发送消息的方法
    send(type, data = null) {
        type = this.httpPrefix + type
        if (!this.isConnected()) {
            if (this.reconnectCount === this.reconnectMaxCount) {
                this.reconnectCount = 0
                this.reconnect()
            }
            this.printLog('未连接到聊天服务器，请检查网络是否正常')
            return new Promise((resolve, reject) => {
                reject('未连接到聊天服务器，请检查网络是否正常')
            })
        }
        if (typeof data !== 'object') {
            data = null
        }
        let requestId = this.uuid()
        let param = {request_id: requestId, type: type}
        if (data !== null) {
            param = Object.assign(param, data)
        }
        if (type !== 'ping') {
            //console.log(param)
        }
        return new Promise((resolve, reject) => {
            this.promisePool[requestId] = {
                type,
                resolve,
                reject
            }
            this.websocket.send(JSON.stringify(param)); // 发送消息到WebSocket服务器
        })
    }

    // 关闭连接
    close() {
        if (this.websocket) {
            this.websocket.close();
        }
        clearTimeout()
        clearInterval()
    }

    // 是否已连接
    isConnected() {
        return this.websocket !== null && this.websocket.readyState === WebSocket.OPEN
    }

    // 开始心跳检测
    startHeartbeat() {
        // 每N秒发送一次心跳消息
        this.heartbeatInterval = setInterval(() => {
            this.send('/ping', null).then(() => {}).catch(() => {})
        }, this.pingInterval) // 每N秒发送一次心跳
    }

    // 停止心跳检测
    stopHeartbeat() {
        if (this.heartbeatInterval) {
            clearInterval(this.heartbeatInterval)
        }
    }

    // 重连
    reconnect() {
        if (this.reconnectCount < this.reconnectMaxCount) {
            setTimeout(() => {
                if (!this.isConnected()) {
                    this.reconnectCount ++
                    this.printLog('WebSocket第' + this.reconnectCount + '次重连')
                    this.open()
                }
            }, this.reconnectInterval)
        }
    }

    // 生成唯一ID
    uuid() {
        return Date.now().toString() + Math.random().toString().substring(2, 9);
    }

    // 打印日志
    printLog(msg) {
        console.log('WebSocketClient：' + msg)
    }
}