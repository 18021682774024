<template>
  <div class="menu-box" v-if="loginChatMember">
    <div class="connect-status" title="网络未连接" v-if="!connected">
      <el-image :src="require('@/assets/connect-fail.png')"></el-image>
    </div>
    <div class="avatar">
      <el-image :src="loginChatMember.avatar"></el-image>
    </div>
    <div class="menu-list">
      <div class="menu-item" v-for="(item, index) in menuList" :key="index" @click="selectMenu(item.code)">
        <el-image :src="item.active_icon" v-if="item.code === activeMenu"></el-image>
        <el-image :src="item.icon" v-else></el-image>
        <div class="unread-count" v-if="item.code === 'chat' && unreadCount > 0">{{ unreadCount }}</div>
      </div>
    </div>
    <div class="setting" @click="setting">
      <el-image :src="require('@/assets/setting.png')"></el-image>
    </div>
    <setting-dialog ref="settingDialog" @logout="logout"></setting-dialog>
  </div>
</template>

<script>

import SettingDialog from "@/components/SettingDialog";
export default {
  name: 'MenuBox',
  components: {SettingDialog},
  props: {
    loginChatMember: {
      type: Object,
      default: null
    },
    activeMenu: {
      type: String,
      default: '',
    },
    chatList: {
      type: Array,
      default: [],
    },
    connected: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    unreadCount: function () {
      let count = 0
      this.chatList.forEach((item) => {
        count += item.unread_count
      })
      return count
    }
  },
  data() {
    return {
      menuList: [
        { name: '聊天', code: 'chat', icon: require('@/assets/chat.png'), active_icon: require('@/assets/chat-active.png') },
        { name: '好友', code: 'friend', icon: require('@/assets/friend.png'), active_icon: require('@/assets/friend-active.png') },
      ],
    }
  },
  methods: {
    // 选择菜单
    selectMenu(code) {
      this.$emit('SelectMenu', code)
    },

    // 设置
    setting() {
      this.$refs.settingDialog.open()
    },

    // 退出登录
    logout() {
      this.$messageBox.confirm(
          '你确定要退出登录吗',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
        this.$api.logout().then(() => {
          this.$api.globalData.loginChatMember = null
          this.$emit('SetData')
        }).catch((msg) => {
          this.$message.error(msg)
        })
      }).catch(() => {})
    }
  }
}
</script>
<style lang="scss" scoped>
.menu-box {
  box-sizing: border-box;
  height: 700px;
  padding: 5px 10px 20px;
  overflow: hidden;
  position: relative;

  .connect-status {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 20px;
    height: 20px;
  }

  .avatar {
    width: 39px;
    height: 39px;
    cursor: pointer;
  }

  .menu-list {
    width: 39px;
    padding: 20px 0;
    .menu-item {
      width: 30px;
      height: 30px;
      margin: 20px 5px;
      cursor: pointer;
      position: relative;
      .unread-count {
        position: absolute;
        right: -10px;
        top: -4px;
        font-size: 10px;
        line-height: 15px;
        padding: 0 5px;
        border-radius: 5px;
        color: #FFFFFF;
        background: #f56c6c;
      }
    }
  }

  .setting {
    position: absolute;
    left: 15px;
    bottom: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}
</style>
