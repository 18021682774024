<template>
  <div class="friend-list">
    <div class="add-friend" title="添加好友" @click="addFriend">
      <el-image :src="require('@/assets/add-friend.png')"></el-image>
    </div>
    <div class="item-box" v-if="friendData && friendData.apply_list && friendData.apply_list.length > 0">
      <div class="item-label">
        <div class="icon">
          <el-image :src="require('@/assets/common-right.png')"></el-image>
        </div>
        <div>新的朋友</div>
        <div class="count">{{ friendData.apply_list.length }}</div>
      </div>
      <div class="item-list">
        <div class="item-detail" v-for="(item, index) in friendData.apply_list" :key="index" @click="agreeChatFriend(item.id)">
          <div class="avatar">
            <el-image :src="item.avatar"></el-image>
          </div>
          <div>
            <div class="nickname" style="line-height: 15px;">{{ item.nickname }}</div>
            <div class="message" style="line-height: 15px;">{{ item.message }}</div>
          </div>
          <div class="status">{{ item.is_agree ? '已添加' : '等待验证' }}</div>
        </div>
      </div>
    </div>
    <div class="item-box" v-if="friendData && friendData.group_list">
      <div class="item-label">
        <div class="icon">
          <el-image :src="require('@/assets/common-right.png')"></el-image>
        </div>
        <div>群聊</div>
        <div class="count">{{ friendData.group_list.length }}</div>
      </div>
      <div class="item-list">
        <div class="item-detail" v-for="(item, index) in friendData.group_list" :key="index">
          <div class="avatar">
            <el-image :src="item.avatar"></el-image>
          </div>
          <div class="nickname">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="item-box" v-if="friendData && friendData.friend_list">
      <div class="item-label">
        <div class="icon">
          <el-image :src="require('@/assets/common-right.png')"></el-image>
        </div>
        <div>联系人</div>
        <div class="count">{{ friendData.friend_list.length }}</div>
      </div>
      <div class="item-list">
        <div class="item-detail" v-for="(item, index) in friendData.friend_list" :key="index">
          <div class="avatar">
            <el-image :src="item.avatar"></el-image>
          </div>
          <div class="nickname">{{ item.nickname }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FriendList',
  props: {
    friendData: {
      type: Object,
      default: null,
    },
  },
  methods: {
    // 添加好友
    addFriend() {
      this.$messageBox.prompt("请输入好友账号", '添加好友', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
      }).then((val) => {
        if (val.length === 0) {
          this.$message.warning('好友账号不能为空')
          return
        }
        this.$api.addChatFriend(val).then(() => {
          this.$message.success('已提交好友申请，等待好友通过')
        }).catch((msg) => {
          this.$message.error(msg)
        })
      }).catch(() => {
      })
    },

    // 同意好友申请
    agreeChatFriend: function (chatFriendId) {
      this.$api.agreeChatFriend(chatFriendId).then(() => {
        this.$message.success('通过好友申请成功')
        this.$parent.getFriendData()
      }).catch((msg) => {
        this.$message.error(msg)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.friend-list {
  width: 299px;
  height: 640px;
  overflow: auto;
  padding-top: 50px;
  position: relative;

  .add-friend {
    position: absolute;
    top: 5px;
    right: 10px;
    width: 30px;
    height: 30px;
    padding: 5px;
    cursor: pointer;
  }

  .item-box {
    padding: 5px 10px;

    .item-label {
      height: 20px;
      line-height: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;

      .icon {
        width: 15px;
        height: 15px;
        margin-right: 2px;
      }

      .count {
        position: absolute;
        right: 10px;
        top: 0;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        color: #999;
      }
    }

    .item-list {
      padding-left: 15px;

      .item-detail {
        display: flex;
        align-items: center;
        margin: 5px 0;
        padding: 5px 0;
        position: relative;
        border-bottom: 1px solid #FFF;
        cursor: pointer;

        .avatar {
          width: 30px;
          height: 30px;
        }

        .nickname {
          padding: 0 10px;
          line-height: 30px;
          font-size: 12px;
          color: #666;
        }

        .message {
          padding: 0 10px;
          line-height: 15px;
          font-size: 10px;
          color: #666;
        }

        .status {
          position: absolute;
          top: 5px;
          right: 10px;
          font-size: 10px;
        }
      }
    }
  }
}

</style>
