/**
 * API请求
 */

import WebSocketClient from "@/api/WebSocketClient";

export default class ApiRequest {
    // 构造器
    constructor() {
        this.wsUrl = process.env.VUE_APP_WS_API
        this.wsHttpPrefix = '/api/app'
        this.wsClient = null
        this.connected = false
        this.closeCallback = null
        this.messageCallback = null
        this.globalData = {
            loginChatMember: null,
            chatList: [],
        }
    }

    // 初始化
    init() {
        this.wsClient = new WebSocketClient(this.wsUrl, this.wsHttpPrefix, () => {
            this.connected = true
        }, () => {
            this.connected = false
            this.closeCallback && this.closeCallback()
        }, (message) => {
            this.messageCallback && this.messageCallback(message)
        })
        this.wsClient.open()
    }

    // 销毁
    destroy() {
        if (this.wsClient) {
            this.wsClient.close()
        }
    }

    // 设置消息回调
    setMessageCallback(callback) {
        this.messageCallback = callback
    }

    // 设置关闭回调
    setCloseCallback(callback) {
        this.closeCallback = callback
    }

    /*************  以下为请求接口 **************/

    // 登录
    login(account, password) {
        return this.wsClient.send('/login', { account: account, password: password })
    }

    // 退出登录
    logout() {
        return this.wsClient.send('/logout', null)
    }

    // 获取聊天列表
    getChatList() {
        return this.wsClient.send('/chatMessages/chats', null)
    }

    // 获取朋友数据
    getFriendData() {
        return this.wsClient.send('/chatFriends/friendData', null)
    }

    // 检查是否已是好友
    checkIsFriend(chatMemberId) {
        return this.wsClient.send('/chatFriends/checkIsFriend', { chat_member_id: chatMemberId })
    }

    // 添加好友
    addChatFriend(account) {
        return this.wsClient.send('/chatFriends/addFriend', { account: account })
    }

    // 同意好友请求
    agreeChatFriend(chatFriendId) {
        return this.wsClient.send('/chatFriends/agreeFriend', {chat_friend_id: chatFriendId})
    }

    // 获取消息列表
    getChatMessageList(param) {
        return this.wsClient.send('/chatMessages', param)
    }

    // 发送消息
    sendChatMessage(param) {
        return this.wsClient.send('/chatMessages/sendMessage', param)
    }

    // 已读消息
    readChatMessage(chatMessageIds) {
        return this.wsClient.send('/chatMessages/readMessage', {chat_message_ids: chatMessageIds})
    }

    // 翻译
    translateChatMessageContentText(chatMessageId, language) {
        return this.wsClient.send('/chatMessages/translateMessageContentText', { chat_message_id: chatMessageId, language: language })
    }
}
