<template>
  <div id="app">
    <div class="app-login" v-show="loginChatMember === null">
      <login-box ref="loginBox" @LoginSuccess="loginSuccess"></login-box>
    </div>
    <el-container class="app-wrapper" v-show="loginChatMember">
      <el-aside class="aside" width="360px">
        <div class="menu-aside">
          <menu-box ref="menuBox"
                    @SelectMenu="selectMenu"
                    @SetData="setData"
                    :connected="connected"
                    :active-menu="activeMenu"
                    :chat-list="chatList"
                    :login-chat-member="loginChatMember"></menu-box>
        </div>
        <div class="list-aside">
          <chat-list ref="chatList"
                     v-if="activeMenu === 'chat'"
                     :chat-list="chatList"
                     :login-chat-member="loginChatMember"
                     :interactive-chat-member="interactiveChatMember"
                     @SelectSpecialChat="selectSpecialChat"
                     @select="selectChat"></chat-list>
          <friend-list ref="friendList"
                       v-if="activeMenu === 'friend'"
                       :friend-data="friendData"></friend-list>
        </div>
      </el-aside>
      <el-main class="main">
        <div class="chat-box">
          <chat-detail ref="chatDetail"
                       v-if="activeMenu === 'chat'"
                       :login-chat-member="loginChatMember"
                       :interactive-chat-member="interactiveChatMember"
                       @ScrollToTop="getMoreChatMessage"
                       @translate="translate"
                       @sendChatMessage="sendChatMessage"></chat-detail>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import LoginBox from "@/components/LoginBox";
import MenuBox from "@/components/MenuBox";
import ChatList from "@/components/ChatList";
import FriendList from "@/components/FriendList";
import ChatDetail from "@/components/ChatDetail";
import {CacheKey} from "@/util/constant";
import {parseUrlParams} from "@/util/utils";

export default {
  name: 'App',
  components: {
    ChatList,
    FriendList,
    ChatDetail,
    MenuBox,
    LoginBox,
  },
  data() {
    return {
      activeMenu: '',
      loginChatMember: null,
      connected: false,
      chatList: [],
      friendData: null,
      interactiveChatMember: null,

      selectSpecialChatData: null,
    }
  },
  mounted() {
    this.setData()
    let urlParams = parseUrlParams(new URL(window.location.href))
    if (urlParams.account && urlParams.password) {
      this.$db.set(CacheKey.staffAccount, { account: urlParams.account, password: urlParams.password })
      this.$db.set(CacheKey.loginStatus, '1')
    }
    this.$api.setMessageCallback((message) => {
      if (message.type === 'connected') {
        // 连接打开成功
        this.connected = true
        this.$nextTick(() => {
          if (this.$refs.loginBox && this.$db.get(CacheKey.loginStatus) === '1') {
            setTimeout(() => {
              this.$refs.loginBox.login()
            }, 100)
          }
        })
      } else if (message.type === 'new_chat_message') {
        // 获取新消息
        if (this.loginChatMember) {
          this.handleNewChatMessage(message.data)
        }
      } else if (message.type === 'new_chat_friend') {
        // 新好友申请或好友申请通过
        this.getFriendData()
      }
    })
    this.$api.setCloseCallback(() => {
      this.connected = false
    })
    this.$api.init()
  },
  beforeUnmount() {
    this.$api.destroy()
  },
  methods: {
    // 设置数据
    setData() {
      this.loginChatMember = this.$api.globalData.loginChatMember
    },

    // 选择菜单
    selectMenu(code) {
      this.activeMenu = code
      if (code === 'chat') {
        this.getChatList()
      } else if (code === 'friend') {
        this.getFriendData()
      }
    },

    // 登录成功
    loginSuccess() {
      this.setData()
      this.selectMenu('chat')
      this.$alert('建议使用谷歌浏览器打开哈信聊天页面！由于浏览器限制，如用户没有与网页进行交互则无法播放声音，因此请先点击"我已知悉"按钮，方可接收到消息提示音。', '说明', {
        confirmButtonText: '我已知悉',
        callback: action => {
          console.log(action)
        }
      })
    },

    // 选择聊天
    selectSpecialChat(data) {
      this.selectSpecialChatData = data
      this.getChatList()
    },

    // 获取聊天列表
    getChatList() {
      this.$api.getChatList().then((data) => {
        this.chatList = data
        if (this.chatList.length > 0) {
          if (this.selectSpecialChatData) {
            // 选指定的聊天记录
            let needSelectIndex = -1
            this.chatList.forEach((item, index) => {
              if (item.chat_member_id === this.selectSpecialChatData.chat_member_id && item.chat_group_id === this.selectSpecialChatData.chat_group_id) {
                needSelectIndex = index
              }
            })
            if (needSelectIndex !== -1) {
              this.selectChat(needSelectIndex)
            }
            this.selectSpecialChatData = null
          } else if (this.interactiveChatMember === null) {
            // 选第一个聊天记录
            // this.selectChat(0)
          }
        }
      }).catch((msg) => {
        this.$message.error(msg)
      })
    },

    // 获取好友数据
    getFriendData() {
      this.$api.getFriendData().then((data) => {
        this.friendData = data
      }).catch((msg) => {
        this.$message.error(msg)
      })
    },

    // 获取消息列表
    getChatMessageList(action) {
      if (this.interactiveChatMember) {
        let oldList = this.interactiveChatMember.chat_message_list
        let param = {
          chat_member_id: this.interactiveChatMember.chat_member_id,
          chat_group_id: this.interactiveChatMember.chat_group_id,
          last_id: '',
          limit: 10,
        }
        if (action === 'more' && oldList.length > 0) {
          param.last_id = oldList[0].id
        }
        this.$api.getChatMessageList(param)
            .then((data) => {
              let newList = []
              if (action === 'refresh') {
                if (oldList.length > 0) {
                  // Todo 原本已有数据，合并数据，然后去除ID重复的记录，按ID排序
                  newList = oldList
                } else {
                  // 原本没数据
                  newList = data.list.reverse()
                }
              } else if (action === 'more') {
                 // 上拉获取更多记录，将输入一个一个插入旧数组前面
                newList = oldList
                data.list.forEach((item) => {
                  newList.unshift(item)
                })
              }

              this.chatList.forEach((item, index) => {
                if (item.chat_member_id === data.other_chat_member_id && item.chat_group_id === data.other_chat_group_id) {
                  this.chatList[index].chat_message_list = newList
                  this.chatList[index].unread_count = data.unread_count
                }
              })
              if (this.interactiveChatMember
                  && this.interactiveChatMember.chat_member_id === data.other_chat_member_id
                  && this.interactiveChatMember.chat_group_id === data.other_chat_group_id) {
                this.interactiveChatMember.chat_message_list = newList
              }
              if (action === 'refresh') {
                this.scrollToMessageListBottom()
              } else if (action === 'more') {
                this.$refs.chatDetail.keepScrollContent(this.$refs.chatDetail.$refs.messageList.scrollHeight)
              }
            })
            .catch((msg) => {
              this.$message.error(msg)
            })
      }
    },

    // 选择聊天
    selectChat(index) {
      this.$refs.chatDetail.clearText()
      let chat = this.chatList[index]
      if (this.interactiveChatMember
          && this.interactiveChatMember.chat_member_id === chat.chat_member_id
          && this.interactiveChatMember.chat_group_id === chat.chat_group_id) {
        return
      }
      this.interactiveChatMember = {
        chat_member_id: chat.chat_member_id,
        chat_member_account: chat.chat_member_account,
        chat_group_id: chat.chat_group_id,
        can_chat: chat.can_chat,
        list_type: chat.list_type,
        name: chat.name,
        avatar: chat.avatar,
        chat_message_list: chat.chat_message_list,
      }
      this.$refs.chatDetail.scrollToMessageListBottom()
      this.getChatMessageList('refresh')
    },

    // 获取更多聊天信息
    getMoreChatMessage() {
      this.getChatMessageList('more')
    },

    // 发送聊天消息
    sendChatMessage(param) {
      this.$api.sendChatMessage(param).then(() => {
        this.$refs.chatDetail.clearText()
      }).catch((msg) => {
        this.$message.error(msg)
      })
    },

    // 翻译
    translate(param) {
      let interactiveChatMember = param.interactive_chat_member
      this.$api.translateChatMessageContentText(param.chat_message_id, param.language).then((res) => {
        this.chatList.forEach((chatDetail, chatIndex) => {
          if (chatDetail.chat_group_id === interactiveChatMember.chat_group_id && chatDetail.chat_member_id === interactiveChatMember.chat_member_id) {
            chatDetail.chat_message_list.forEach((item, index) => {
              if (item.id === res.id) {
                this.chatList[chatIndex].chat_message_list[index] = res
              }
            })
          }
        })
      }).catch((msg) => {
        this.$message.error(msg)
      })
    },

    // 滚动到底部
    scrollToMessageListBottom() {
      if (this.$refs.chatDetail) {
        this.$refs.chatDetail.scrollToMessageListBottom()
      }
    },

    // 处理新消息
    handleNewChatMessage(newChatMessage) {
      let isFriendMessage = newChatMessage.to_chat_member_id > 0 // 好友消息、群组消息
      let isSelfSend = this.loginChatMember.id === newChatMessage.from_chat_member_id // 自己发出的消息、别人发来的消息
      let isRelateToInteractiveChatMember = false // 是否跟当前正在聊天的用户相关
      if (this.interactiveChatMember) {
        if (newChatMessage.to_chat_member_id > 0) {
          isRelateToInteractiveChatMember = this.interactiveChatMember.chat_member_id === newChatMessage.from_chat_member_id || this.interactiveChatMember.chat_member_id === newChatMessage.to_chat_member_id
        } else if (newChatMessage.to_chat_group_id > 0) {
          isRelateToInteractiveChatMember = this.interactiveChatMember.chat_group_id === newChatMessage.to_chat_group_id
        }
      }

      let relateChatIndex = -1  // 该条消息跟消息列表中的哪个记录有关
      this.chatList.forEach((item, index) => {
        if (isFriendMessage && (item.chat_member_id === newChatMessage.from_chat_member_id || item.chat_member_id === newChatMessage.to_chat_member_id)) {
          relateChatIndex = index
        } else if (!isFriendMessage && item.chat_group_id === newChatMessage.to_chat_group_id) {
          relateChatIndex = index
        }
      })
      if (relateChatIndex !== -1) {
        // 修改聊天列表数据
        this.chatList[relateChatIndex].chat_message_list.push(newChatMessage)
        if (newChatMessage.type === 'text') {
          this.chatList[relateChatIndex].last_message_txt = newChatMessage.content.text
        }
        this.chatList[relateChatIndex].last_message_time = this.$utils.dateStrToTime(newChatMessage.created_at) / 1000
        this.chatList[relateChatIndex].last_message_time_txt = newChatMessage.time_txt
        //console.log('新消息来源：' + (isSelfSend ? '自己发送' : '别人发送') + ' ==== 跟当前正在聊天用户有关：' + (isRelateToInteractiveChatMember ? '是' : '否'))
        if (!isSelfSend && !isRelateToInteractiveChatMember) {
          // 别人发来的消息，而且不是当前正在聊天的人，则未读数+1
          this.chatList[relateChatIndex].unread_count += 1
        }
        if (!isSelfSend && isRelateToInteractiveChatMember) {
          // 别人发来的消息，而且是当前正在聊天的人，则将相应记录改为'已读'
          this.$api.readChatMessage(newChatMessage.id)
        }
      } else {
        // 该消息对应的好友或群组不在聊天列表中，说明是新好友或新群组
        this.getChatList()
      }
      this.scrollToMessageListBottom()
      this.chatList = this.$utils.bubbleSort(this.chatList, 'last_message_time', 'desc')
      if (!isSelfSend) {
        this.playAudio()
      }
    },

    // 播放音频
    playAudio: function () {
      let audioUrl = "https://api.tgb.cool/resources/media/new-message.mp3"
      if (audioUrl) {
        (new Audio(audioUrl)).play().catch((e) => {
          console.log(e)
          this.$notify.warning('有新消息')
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>

:deep(.el-image__inner) {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.app-wrapper {
  box-sizing: content-box;
  width: 1060px;
  height: 700px;
  border: 1px solid #DEDEDE;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-wrap: wrap;

  .aside {
    display: flex;

    .menu-aside {
      box-sizing: border-box;
      width: 60px;
      height: 700px;
      background: #DEE0E0;
      border-right: 1px solid #DEDEDE;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .list-aside {
      box-sizing: border-box;
      width: 300px;
      background: #F6F6F6;
      border-right: 1px solid #DEDEDE;
    }
  }

  .main {
    padding: 0;

    .chat-box {
      width: 700px;
      height: 700px;
      background: #F1F1F1;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

.app-login {
  box-sizing: content-box;
  width: 300px;
  height: 400px;
  border: 1px solid #DEDEDE;
  border-radius: 10px;
}
</style>
