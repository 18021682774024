<template>
  <div class="chat-detail">
    <div v-if="interactiveChatMember">
      <div class="name">
        {{ interactiveChatMember.name }}
        <div class="info" @click="showFriendInfo" v-if="interactiveChatMember.chat_member_id > 0">
          <el-image :src="require('@/assets/friend-info.png')"></el-image>
        </div>
      </div>
      <div ref="messageList"
           class="message-list"
           @scroll="handleScroll"
           :style="{ 'height': interactiveChatMember.can_chat ? '490px' : '640px' }">
        <div v-for="(item, index) in messageList" :key="index">
          <div v-if="interactiveChatMember.list_type === 'chat'" class="message-item">
            <div class="time" v-if="item.hidden_time === false">{{ item.time }}</div>
            <div class="box" :class="{ 'box-self': item.sender === 'self' }">
              <div class="avatar">
                <el-image style="width: 38px;height: 38px;border-radius: 5px;border: 1px solid #eee;" :src="item.avatar"></el-image>
              </div>
              <div class="content">
                <div class="text" v-if="item.type === 'text'" @dblclick="translate(index)">{{ item.text }}</div>
                <div class="pic" v-else-if="item.type === 'pic'"><el-image :src="item.pic"></el-image></div>
                <div class="text" v-else-if="item.type === 'link'">{{ item.link_txt }}</div>
              </div>
            </div>
            <div class="box" :class="{ 'box-self': item.sender === 'self' }" style="margin-top: 10px;" v-if="item.translate_text">
              <div class="avatar"></div>
              <div class="content">
                <div class="text" style="background: #FFF;">{{ item.translate_text }}</div>
              </div>
            </div>
          </div>
          <div v-else-if="interactiveChatMember.list_type === 'article'" class="message-item">
            <div class="time">{{ item.time }}</div>
            <div class="box box-article">
              <div class="content">
                <div class="text" v-if="item.type === 'text'" @dblclick="translate(index)">{{ item.text }}</div>
                <div class="pic" v-else-if="item.type === 'pic'"><el-image :src="item.pic"></el-image></div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="messageList.length === 0 && !interactiveChatMember.can_chat" style="margin-top: 100px;">
          <lxs-no-data content="暂无相关内容"></lxs-no-data>
        </div>
      </div>
      <div class="send-box" v-if="interactiveChatMember.can_chat">
        <div class="action-list">
          <div class="action-item">
            <el-image :src="require('@/assets/pic.png')" @click="selectPic"></el-image>
            <input ref="picUploadInput" type="file" @change="selectPicDone" style="display: none;" />
          </div>
        </div>
        <el-input v-model="willSendText"
                  type="textarea"
                  placeholder="请输入聊天内容，按Ctrl+Enter换行，按Enter键发送"
                  :autosize="{ minRows: 4, maxRows: 4 }"
                  resize="none"
                  :autofocus="true"
                  :show-word-limit="true"
                  @keydown="handleKeyCode($event)"
                  clearable></el-input>
      </div>
    </div>
    <friend-info-dialog ref="friendInfoDialog"></friend-info-dialog>
  </div>
</template>

<script>
import LxsNoData from "@/components/lxs-no-data";
import FriendInfoDialog from "@/components/FriendInfoDialog";

export default {
  name: 'ChatDetail',
  components: {LxsNoData, FriendInfoDialog},
  props: {
    loginChatMember: {
      type: Object,
      default: null,
    },
    interactiveChatMember: {
      type: Object,
      default: null,
    }
  },
  computed: {
    // 消息列表
    messageList() {
      let list = []
      if (this.interactiveChatMember && this.interactiveChatMember.chat_message_list) {
        let preTime = 0
        this.interactiveChatMember.chat_message_list.forEach((item) => {
          let currentTime = this.$utils.dateStrToTime(item.created_at)
          let temp = {
            id: item.id,
            name: item.from_chat_member_nickname,
            avatar: item.from_chat_member_avatar,
            type: item.type,
            text: '',
            text_language: item.content.text_language,
            translate_text: '',
            pic: '',
            time: item.time_txt,
            hidden_time: false,
            sender: this.interactiveChatMember.chat_member_id === item.from_chat_member_id ? 'other' : 'self',
          }
          if (item.type === 'text') {
            temp.text = item.content.text
            let key = 'translate_text_' + temp.text_language + '_' + this.language
            if (item.content.translate_chat_member_id_arr
                && item.content.translate_chat_member_id_arr.indexOf(this.loginChatMember.id) !== -1
                && item.content[key]) {
              // 用户翻译过，且是对应语言
              temp.translate_text = item.content[key]
            }
          } else if (item.type === 'pic') {
            temp.pic = item.content.pic
          } else if (item.type === 'link') {
            let linkData = JSON.parse(item.content.link_data)
            temp.link_txt = '[链接]' + (linkData.name ? linkData.name : '')
          }
          if (item.to_chat_member_id > 0) {
            // 若当前时间跟上一个时间相差不超过3分钟，就不需要显示
            temp.hidden_time = currentTime - preTime < 3 * 60 * 1000
          }
          list.push(temp)
          preTime = currentTime
        })
      }
      return list
    },
    // 语言
    language() {
      let language = navigator.language || navigator.userLanguage
      if (language === 'zh-CN') {
        language = 'zh'
      }
      return language
    },
  },
  data() {
    return {
      willSendText: '',
	    width: 100,
	    height: 100,
    }
  },
	mounted() {
		const that = this;
		document.body.addEventListener('paste', function (e) {
			// 获取当前输入框内的文字
			const oldText = that.willSendText
			// 读取图片
			let items = e.clipboardData && e.clipboardData.items;
			let file = null;
			if (items && items.length) {
				// 检索剪切板items
				for (let i = 0; i < items.length; i++) {
					if (items[i].type.indexOf('image') !== -1) {
						file = items[i].getAsFile();
						break;
					}
				}
			}
			if (!file) return;
			// 预览图片
			const reader = new FileReader();
			reader.onload = function (event) {
				// 图片内容
				const imgContent = event.target.result;
				// 创建img标签
				let img = document.createElement('img');//创建一个img
				// 获取当前base64图片信息，计算当前图片宽高以及压缩比例
				let imgObj = new Image();
				let imgWidth = "";
				let imgHeight = "";
				let scale = 1;
				imgObj.src = imgContent;
				imgObj.onload = function () {
					// 计算img宽高
					if (this.width < 400) {
						imgWidth = this.width;
						imgHeight = this.height;
					} else {
						// 输入框图片显示缩小10倍
						imgWidth = this.width / 10;
						imgHeight = this.height / 10;
						// 图片宽度大于1920，图片压缩5倍
						if (this.width > 1920) {
							// 真实比例缩小5倍
							scale = 5;
						}
					}
					// 设置可编辑div中图片宽高
					img.width = imgWidth;
					img.height = imgHeight;
					// 压缩图片，渲染页面
					that.compressPic(imgContent, scale, function (newBlob, newBase) {
						// 删除可编辑div中的图片名称
						that.willSendText = oldText;
						img.src = newBase; //设置链接
						// 发送图片
						that.sendPicChatMessage(newBase)
					});
				};
			};
			reader.readAsDataURL(file);
		});
	},
	methods: {
    // 清除文本
    clearText() {
      this.willSendText = ''
    },

    // 发送文本消息
    sendTextChatMessage() {
      if (this.interactiveChatMember) {
        if (this.willSendText.length === 0) {
          this.$message.error('消息内容不能为空')
          return
        }
        this.$emit('sendChatMessage', {
          to_chat_member_id: this.interactiveChatMember.chat_member_id,
          to_chat_group_id: this.interactiveChatMember.chat_group_id,
          message_type: 'text',
          message_content: this.willSendText
        })
      }
    },

    // 发送图片消息
    sendPicChatMessage(base64) {
      if (this.interactiveChatMember) {
        this.$emit('sendChatMessage', {
          to_chat_member_id: this.interactiveChatMember.chat_member_id,
          to_chat_group_id: this.interactiveChatMember.chat_group_id,
          message_type: 'pic',
          message_content: base64
        })
      }
    },

    // 处理键盘输入
    handleKeyCode(event) {
      if (event.keyCode === 13) {
        if (!event.ctrlKey) {
          event.preventDefault();
          this.sendTextChatMessage();
        } else {
          this.willSendText += '\n';
        }
      }
    },

    // 处理滚动
    handleScroll(event) {
      const div = this.$refs.messageList
      let maxScrollTop = div.scrollHeight - div.clientHeight

      if (event.target.scrollTop === 0) {
        // 滚动到顶部
        this.$emit('ScrollToTop')
      } else if (event.target.scrollTop === maxScrollTop) {
        // 滚动到底部
        this.$emit('ScrollToBottom')
      }
    },

    // 查看好友信息
    showFriendInfo() {
      this.$refs.friendInfoDialog.open(this.interactiveChatMember)
    },

    // 滚动到底部
    scrollToMessageListBottom() {
      this.$nextTick(() => {
        const div = this.$refs.messageList
        if (div) {
          div.scrollTop = div.scrollHeight
        }
      })
    },

    // 保持滚动内容
    keepScrollContent(oldScrollHeight) {
      this.$nextTick(() => {
        const div = this.$refs.messageList
        div.scrollTop = div.scrollHeight - oldScrollHeight
      })
    },

    // 翻译
    translate(index) {
      if (this.language === this.messageList[index].text_language) {
        this.$message.warning('无需翻译')
        return
      }
      this.$emit('translate', {
        interactive_chat_member: this.interactiveChatMember,
        chat_message_id: this.messageList[index].id,
      })
    },

    // 选择图片
    selectPic() {
      this.$refs.picUploadInput.click()
    },

    // 选择完图片
    selectPicDone(event) {
      const file = event.target.files[0];
      if (file) {
        let allowTypeArr = ['image/png', 'image/jpeg']
        let allowSize = 102400
        if (allowTypeArr.indexOf(file.type) === -1) {
          this.$message.warning('该文件类型不支持，目前只支持png、jpeg图片')
          return
        }
        if (file.size > allowSize) {
          this.$message.warning('该文件大小超过限制')
          return
        }
        const reader = new FileReader();
        reader.onload = (e) => {
          this.sendPicChatMessage(e.target.result)
        };
        reader.readAsDataURL(file);
      }
    },

		// 参数: base64地址,压缩比例，回调函数(返回压缩后图片的blob和base64)
		compressPic(base64, scale, callback) {
			const that = this;
			let _img = new Image();
			_img.src = base64;
			_img.onload = function () {
				let _canvas = document.createElement("canvas");
				let w = this.width / scale;
				let h = this.height / scale;
				_canvas.setAttribute("width", w);
				_canvas.setAttribute("height", h);
				_canvas.getContext("2d").drawImage(this, 0, 0, w, h);
				let base64 = _canvas.toDataURL("image/jpeg");
				// 当canvas对象的原型中没有toBlob方法的时候，手动添加该方法
				if (!HTMLCanvasElement.prototype.toBlob) {
					Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
						value: function (callback, type, quality) {
							let binStr = atob(this.toDataURL(type, quality).split(',')[1]),
								len = binStr.length,
								arr = new Uint8Array(len);
							for (let i = 0; i < len; i++) {
								arr[i] = binStr.charCodeAt(i);
							}
							callback(new Blob([arr], {type: type || 'image/png'}));
						}
					});
				} else {
					_canvas.toBlob(function (blob) {
						if (blob.size > 1024 * 1024) {
							that.compressPic(base64, scale, callback);
						} else {
							callback(blob, base64);
						}
					}, "image/jpeg");
				}
			}
		},
  }
}
</script>

<style lang="scss" scoped>
.chat-detail {
  width: 700px;
  height: 700px;
  background: #F3F3F3;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  .name {
    width: 700px;
    height: 49px;
    text-align: center;
    line-height: 49px;
    font-weight: bold;
    border-bottom: 1px solid #DFDFDF;
    position: relative;
    .info {
      position: absolute;
      right: 13px;
      top: 13px;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .message-list {
    width: 700px;
    height: 490px;
    overflow: auto;
    margin: 5px 0;

    &::-webkit-scrollbar {
      /* 隐藏滚动条，但依旧具备可以滚动的功能 */
      display: none;
      width: 0;
      height: 0;
      background-color: transparent;
    }

    .message-item {
      width: 680px;
      padding: 10px;
      margin: 2px 0;
    }

    .message-item .time {
      text-align: center;
      font-size: 12px;
      margin-bottom: 5px;
      color: #AAAAAA;
    }

    .message-item .box {
      width: 680px;
      min-height: 40px;
      position: relative;
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
    }

    .message-item .box .avatar {
      width: 40px;
      height: 40px;
    }

    .message-item .box .content{
      width: 520px;
      margin: 0 10px;
      display: flex;
      justify-content: flex-start;
    }

    .message-item .box .content .text {
      position: relative;
      width: auto;
      height: auto;
      display: inline-block;
      background: #FFFFFF;
      color: #11170C;
      padding: 10px;
      line-height: 20px;
      border-radius: 5px;
      font-size: 12px;
    }

    .message-item .box .translate-content {
      position: absolute;
      top: 50px;
      width: 520px;
      margin: 0 10px;
      display: flex;
      justify-content: flex-start;
    }

    .message-item .box .translate-content .text{
      width: auto;
      height: auto;
      display: inline-block;
      background: #FFFFFF;
      color: #11170C;
      padding: 10px;
      line-height: 20px;
      border-radius: 5px;
      font-size: 12px;
    }

    .message-item .box .content .pic {
      width: auto;
      height: auto;
      padding: 10px;
      border-radius: 5px;
      display: inline-block;
      border: 1px solid #FFFFFF;
    }

    .message-item .box-self {
      flex-direction: row-reverse;
    }

    .message-item .box-self .content{
      justify-content: flex-end;
    }

    .message-item .box-self .content .text {
      background: #A9EA7A;
    }

    .message-item .box-self .content .pic {
      border: 1px solid #A9EA7A;
    }

    .message-item .box-article {
      justify-content: center;
    }

    .message-item .box-article .content{
      width: 580px;
      justify-content: center;
    }
  }

  .send-box {
    width: 100%;
    height: 135px;
    padding: 7px 0;
    border-top: 1px solid #DFDFDF;
    background: #FFFFFF;

    .action-list {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      .action-item {
        width: 30px;
        height: 30px;
        margin: 0 10px;
        cursor: pointer;
      }
    }

    :deep(.el-textarea__inner) {
      box-shadow: none;
      //background: #F3F3F3;
    }
  }
}
</style>
