<template>
  <el-dialog
      v-model="visible"
      title="好友信息"
      width="600"
  >
    <div class="friend-info-dialog" v-if="info">
      <div class="info-label">账号信息：</div>
      <div class="account">
        <div class="avatar">
          <el-image :src="info.avatar"></el-image>
        </div>
        <div class="nickname">{{ info.name }}</div>
        <div class="code">（账号：{{ info.chat_member_account }}）</div>
        <div class="copy">
          <el-button style="width: 80px;height: 30px;" @click="copy">复制账号</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'FriendInfo',
  data() {
    return {
      visible: false,
      info: null,
    }
  },
  methods: {
    open(info) {
      this.visible = true
      this.info = info
      console.log(this)
    },
    close() {
      this.visible = false
    },
    // 复制
    async copy() {
      try {
        await navigator.clipboard.writeText(this.info.chat_member_account)
        this.$message.success('复制成功')
      } catch (error) {
        this.$message.warning('复制失败')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.friend-info-dialog {
  .info-label {

  }

  .account {
    display: flex;
    padding: 10px;
    position: relative;

    .avatar {
      width: 30px;
      height: 30px;
    }

    .nickname {
      padding: 0 10px;
      line-height: 30px;
      font-size: 16px;
      font-weight: bold;
    }

    .code {
      line-height: 30px;
    }

    .copy {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}
</style>
