<template>
  <el-dialog
      v-model="visible"
      title="设置"
      width="600"
  >
    <div class="setting-dialog" v-if="chatMember">
      <div class="setting-label">账号信息：</div>
      <div class="account">
        <div class="avatar">
          <el-image :src="chatMember.avatar"></el-image>
        </div>
        <div class="nickname">{{ chatMember.nickname }}</div>
        <div class="code">（账号：{{ chatMember.account }}）</div>
        <div class="logout">
          <el-button style="width: 80px;height: 30px;" @click="logout">退出登录</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'SettingDialog',
  data() {
    return {
      visible: false,
      chatMember: null,
    }
  },
  methods: {
    open() {
      this.visible = true
      this.chatMember = this.$api.globalData.loginChatMember
    },
    close() {
      this.visible = false
    },
    // 退出登录
    logout() {
     this.$emit('logout')
    }
  }
}
</script>

<style lang="scss" scoped>
.setting-dialog {
  .setting-label {

  }

  .account {
    display: flex;
    padding: 10px;
    position: relative;

    .avatar {
      width: 30px;
      height: 30px;
    }

    .nickname {
      padding: 0 10px;
      line-height: 30px;
      font-size: 16px;
      font-weight: bold;
    }

    .code {
      line-height: 30px;
    }

    .logout {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}
</style>
