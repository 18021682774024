const WsUrl = process.env.VUE_APP_WS_API
let prefix_env = 'prod_'
if (WsUrl.indexOf('test') !== -1) {
	prefix_env = 'test_'
} else if (WsUrl.indexOf('dev') !== -1 || WsUrl.indexOf('127.0.0.1') !== -1) {
	prefix_env = 'dev_'
}

// 取值
function get(key) {
	key = prefix_env+ '_' + key;
	try {
		let item = localStorage.getItem(key)
		return item ? JSON.parse(item) : null
	} catch (e) {
		return null;
	}
}

// 赋值
function set(key, value) {
	key = prefix_env+ '_' + key;
	try {
		localStorage.setItem(key, JSON.stringify(value))
		return true
	} catch (e) {
		return false

	}
}

// 移除
function del(key,) {
	key = prefix_env+ '_' + key;
	try {
		localStorage.removeItem(key)
		return true
	} catch (e) {
		return false
	}
}

// 清空
function clear() {
	try {
		localStorage.clear()
		return true
	} catch (e) {
		return false;
	}
}


export {
	get,
	set,
	del,
	clear
}
