<template>
  <div class="login-box">
    <div class="logo">
      <el-image style="width: 100px;height: 100px;border-radius: 5px;border: 1px solid #eee;" :src="require('@/assets/logo.png')"></el-image>
    </div>
    <div class="account">
      <el-input
          v-model="account"
          clearable
          placeholder="请输入账号"
      >
        <template #prepend>账号</template>
      </el-input>
    </div>
    <div class="password">
      <el-input
          v-model="password"
          type="password"
          clearable
          placeholder="请输入密码"
      >
        <template #prepend>账号</template>
      </el-input>
    </div>
    <div class="btn">
      <el-button style="width: 200px;background: #58BE6A;color: #FFFFFF;height: 40px;" @click="login">登录</el-button>
    </div>
  </div>
</template>

<script>
import {CacheKey} from "@/util/constant";
import {md5} from "@/util/md5";

export default {
  name: 'LoginBox',
  data() {
    return {
      account: '',
      password: '',
      staffAccount: '',
      staffPassword: '',
    }
  },
  mounted() {
    let loginAccount = this.$db.get(CacheKey.loginAccount)
    if (loginAccount) {
      this.account = loginAccount.account
      this.password = loginAccount.password
    }
    let staffAccount = this.$db.get(CacheKey.staffAccount)
    if (staffAccount) {
      this.staffAccount = staffAccount.account
      this.staffPassword = staffAccount.password
    }
  },
  methods: {
    // 登录
    login() {
      if (this.staffAccount && this.staffPassword) {
        this.$api.login(this.staffAccount, this.staffPassword).then((res) => {
          this.$api.globalData.loginChatMember = res
          this.staffAccount = ''
          this.staffPassword = ''
          this.$db.del(CacheKey.staffAccount)
          this.$emit('LoginSuccess')
        }).catch((msg) => {
          this.$message.error(msg)
        })
      } else {
        if (this.account.length === 0) {
          this.$message.warning('账号不能为空')
          return
        }
        if (this.password.length === 0) {
          this.$message.warning('密码不能为空')
          return
        }
        this.$db.set(CacheKey.loginAccount, { account: this.account, password: this.password })
        this.$db.set(CacheKey.loginStatus, '1')
        this.$api.login(this.account, md5(this.password)).then((res) => {
          this.$api.globalData.loginChatMember = res
          this.$emit('LoginSuccess')
        }).catch((msg) => {
          this.$message.error(msg)
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.login-box {
  padding: 40px 20px;
  .logo {
    display: flex;
    justify-content: center;
  }
  .account {
    margin: 30px 0;
  }
  .password {
    margin: 30px 0;
  }
  .btn {
    margin: 50px 0;
    display: flex;
    justify-content: center;
  }
}
</style>
